<template>
  <div class="page">
    <b-card>
      <!-- <b-button
        variant="outline-primary"
        class="mb-2"
        @click="$router.push({name: 'Create User'})"
      >
        <feather-icon icon="PlusIcon" />
        Add New User
      </b-button> -->
      <b-table
        responsive="sm"
        :items="users"
        :fields="['invoiceId', 'name', 'email', 'phone', 'price', 'status', 'issueDate', 'actions']"
      >
        <template #cell(invoiceId)="data">
          <router-link :to="'/superadmin/billings/' + $route.params.clientId + '/detail/' + data.item.id">{{data.value}}</router-link>
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="getVarient(data.value)"
          >
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item :to="'/billings/' + data.item.id">
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BBadge, BDropdown, BDropdownItem,
  },
  data() {
    return {
      users: [{
        id: 1,
        name: 'Brian Choi',
        email: 'brian.choi@onikon.com',
        phone: '778.888.3690',
        invoiceId: 1,
        price: '$85',
        status: 'Paid',
        issueDate: 'Mar 17, 2020',
        // role: 'Agent',
        // email: 'brian.choi@onikon.com',
        // phone: '778.888.3690',
        // isAdmin: 1,
        // created: 'Mar 17, 2020',
      }],
    }
  },
  methods: {
    getVarient(status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Closed': return 'danger'
        default: return 'dark'
      }
    },
  },
}
</script>

<style>

</style>
